







































































/**@example
 * ```vue
 *  <!-- <document-viewer
 *    v-if="documents.length > 0"
 *    v-model="showDocuments"
 *    :documents="documents"
 *  /> -->
 * ```
 * 
 * ```typescript
 * computed: {
    documents(): any[] {
      return [
        {
          title: "Doris contract",
          url: `${apiBaseUrl}/patient/getPdfDorisContracts/${this.stepBody.token}`,
        },
        ...
      ];
    },
 * }
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiBaseUrl } from "@/enviorment";
import axios from "axios";
import Vue from "vue";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default Vue.extend({
  name: "document-viewer",
  components: {
    VuePdfApp,
  },
  props: {
    value: Boolean,
    documents: Array,
  },
  data() {
    return {
      dialog: false,
      currentTab: -1,
      loading: true,
      photoId: "",
      loadingPhotoId: false,
      pdfEngineConfig: {
        secondaryToolbar: {
          secondaryOpenFile: false,
          secondaryViewBookmark: false,
          secondaryDownload: true,
        },
        toolbar: {
          toolbarViewerRight: {
            presentationMode: false,
            openFile: false,
            viewBookmark: false,
            download: true,
          },
        },
      },
      buffer: "",
    };
  },
  computed: {
    fileNames(): any[] {
      let c = 0;
      const files = this.documents.map((document: any) => {
        if (document.title == "Credit_Card_Authorization_Form") {
          c++;
        }
        return { title: document.title, count: c };
      });

      return files; // Remove the first empty string
    },
  },
  watch: {
    currentTab(newVal: number, oldVal: number) {
      // Activar el estado cargando cuando se cambia de tab
      if (newVal != oldVal) this.loading = true;
      if (newVal == undefined || this.documents[newVal] == undefined) return;
      this.photoId = "";

      if ((this as any).documents[newVal].title == "Photo ID") {
        this.loadPhotoId((this as any).documents[newVal].procedure);
        return;
      }

      const token = this.$store.state.accessToken;
      const auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "application/pdf",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "application/pdf",
            };

      axios({
        method: "get",
        url: (this as any).documents[newVal].url,
        responseType: "blob",
        headers: auth,
      })
        .then((response) => {
          new Blob([response.data]).arrayBuffer().then((rawData) => {
            (this as any).buffer = rawData;
          });
          this.loading = false;
          this.loadingPhotoId = false;
        })
        .catch(() => {
          (this as any).currentTab = null;
          this.loading = false;
          this.loadingPhotoId = false;
          // notificationError();
        });
    },
  },
  mounted() {
    setTimeout(() => {
      (this as any).currentTab = 1;
    }, 1000);
  },
  methods: {
    showName(name: string, count: number) {
      switch (name) {
        case "Acknowledgement_of_Receipt_of_Notice_of_Privacy_Practices":
          return "Acknowledgement_of_Receipt...";

        case "Credit_Card_Authorization_Form":
          return `Card Authorization (#${count})`;

        case "Video_Photo_Authorization_form":
          return "Media Authorization";
        case "This_agreement_CONTRACT_MIAMI_BODY_W_PATIENT":
          return "Agreement Contract";

        default:
          return name;
      }
    },

    onPageRender(e: any) {
      e.eventBus.dispatch("scalechanged", {
        source: self,
        value: "page-width",
      });

      this.loading = false;
    },
    onInput(state: boolean) {
      this.$emit("input", state);
    },
    loadPhotoId(id: number) {
      const token = this.$store.state.accessToken;
      const auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "application/pdf",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "application/pdf",
            };

      this.loadingPhotoId = true;
      this.loading = false;
      axios({
        method: "get",
        url: `${apiBaseUrl}/patient/getPhotoAuthForm/${id}`,
        responseType: "blob",
        headers: auth,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          const reader = new FileReader();

          reader.onloadend = () => {
            const base64data = reader.result;

            (this as any).photoId = base64data;
          };
          reader.readAsDataURL(blob);
          this.loadingPhotoId = false;
        })
        .catch(() => {
          this.photoId = "";
          this.loadingPhotoId = false;
          // notificationError();
        });
    },
  },
});
